import React, { useState } from "react"
import { Link } from "gatsby"
import handleViewport from "react-in-viewport"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import AnimatedNumber from "animated-number-react"

import Layout from "../hoc/Layout"
import SectionIntro from "../components/Sections/SectionIntro/SectionIntro"
import BooksNetworkAnimation from "../components/BooksNetworkAnimation"

import LogoBooksellers from "../assets/images/about-us/network-booklovers/children_Booksellers_Worldwide.svg"

const AboutUs = props => {
  // Publishers logos import
  /* const importAll = r => {
    return r.keys().map(r)
  }
  const images = importAll(require.context("../assets/images/about-us/publishers/", false, /\.(png|jpe?g|svg)$/)) */

  // Art-directing gatsby image
  const sourcesZoeVoborilova = [
    { ...props.data.zoeVoborilova.childImageSharp.fluid, media: `(max-width: 1920px) and (min-width: 769px)` },
    {
      ...props.data.zoeVoborilovaMobile.childImageSharp.fluid,
      media: `(max-width: 768px) and (min-width: 414px)`,
    },
  ]
  const sourcesHomeLibrary = [
    { ...props.data.homeLibrary.childImageSharp.fluid, media: `(max-width: 1920px) and (min-width: 769px)` },
    {
      ...props.data.homeLibraryMobile.childImageSharp.fluid,
      media: `(max-width: 768px) and (min-width: 414px)`,
    },
  ]

  // Check window size
  const [windowSize, setWindowSize] = useState(null)
  let windowSizeAnimation = false

  React.useEffect(() => {
    const handleResize = () => {
      setWindowSize(window.innerWidth)
    }

    window.addEventListener("resize", handleResize)
    return () => window.removeEventListener("resize", handleResize)
  }, [])

  if (windowSize < 768) {
    windowSizeAnimation = true
  }

  // Animated sections
  const AnimatedSection = props2 => {
    const { inViewport, forwardedRef } = props2
    const formatValue = value => Number(value).toFixed(0)

    const showAnimatedNumberOne = inViewport ? <AnimatedNumber value={11} formatValue={formatValue} /> : 11
    const showAnimatedNumberTwo = inViewport ? <AnimatedNumber value={1} formatValue={formatValue} /> : 1
    const showAnimatedNumberThree = inViewport ? <AnimatedNumber value={68} formatValue={formatValue} /> : 68
    const showAnimatedNumberFour = inViewport ? <AnimatedNumber value={51} formatValue={formatValue} /> : 51
    const showAnimatedNumberFive = inViewport ? <AnimatedNumber value={65} formatValue={formatValue} /> : 65

    return (
      <section className="container-wide archievements-container">
        <h2>Our achievements</h2>

        <div className="row-cols-5 row-cols-sm-1 archievements" ref={forwardedRef}>
          <div className="archievements-block">
            <h3>{showAnimatedNumberOne}K</h3>
            <p>Booklovers</p>
          </div>
          <div className="archievements-block">
            <h3>{showAnimatedNumberTwo}M</h3>
            <p>Books sold in total</p>
          </div>
          <div className="archievements-block">
            <h3>{showAnimatedNumberThree}</h3>
            <p>Publishers we offer</p>
          </div>
          <div className="archievements-block">
            <h3>{showAnimatedNumberFour}</h3>
            <p>European countries</p>
          </div>
          <div className="archievements-block">
            <h3>{showAnimatedNumberFive}K</h3>
            <p>Titles in portfolio</p>
          </div>
        </div>
      </section>
    )
  }
  const AnimatedSection2 = props3 => {
    const { inViewport, forwardedRef } = props3
    return (
      <div className="books-network-right-image-wrap" ref={forwardedRef}>
        {inViewport ? <BooksNetworkAnimation play={true} /> : <BooksNetworkAnimation play={windowSizeAnimation === true ? true : false} />}
      </div>
    )
  }

  const ViewportBlock = handleViewport(AnimatedSection /** options: {}, config: {} **/)
  const ViewportBlock2 = handleViewport(AnimatedSection2 /** options: {}, config: {} **/)
  return (
    <>
      <Layout headerPages pages>
        <SectionIntro page="aboutUs" />

        <div className="aboutUs">
          <ViewportBlock /* onEnterViewport={() => console.log("enter")} onLeaveViewport={() => console.log("leave")} */ />

          <section className="container about">
            <div className="row">
              <div className="col-12 about-title">
                <p className="name">Zoë Voborilova</p>
                <p>
                  <strong>Founder & Managing Director</strong>
                </p>
                <p>
                  <strong>Moonwood and Children's Booksellers Worldwide</strong>
                </p>
              </div>
            </div>

            <div className="row about-detail">
              <div className="col-4 col-md-12 about-detail-left">
                <div className="row">
                  <div className="col-12">
                    <div className="divider hide">
                      <div className="divider-inner-width"></div>
                    </div>
                    <div className="image-container">
                      <Img fluid={sourcesZoeVoborilova} style={{ height: "100%", width: "100%" }} imgStyle={{ objectFit: "contain" }} alt="ZoëVoborilova" />
                    </div>
                    <div className="divider divider-rotate hide show">
                      <div className="divider-inner-height"></div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-8 col-md-12 about-detail-right">
                <div>
                  <p>
                    Originally a professional violinist and member of the Prague Philharmonia, Zoë's interest in education led her to teaching English in Czech preschools and primary schools, before finding her true passion in selling English children's books and supporting other
                    children's booksellers towards achieving business success in their own countries. For many years, Zoë has followed her dream of bringing top quality English books to children and families, providing school book fairs for international, bilingual and state
                    schools in many European countries and supporting a network of booksellers who share the same energy and enthusiasm.
                  </p>
                  <br />
                  <p>
                    Zoë discovered early on that English books are ideal in helping children to learn English as a second language. She now uses her experience to inspire children, parents and teachers, offering English children’s books by a wide range of publishers, in addition
                    to children's books in foreign languages.
                  </p>
                  <br />
                  <p className="mobileHide">
                    Zoë grew up in the North East of England and studied at the Royal Northern College of Music in Manchester, before moving to Prague in 1994 to continue with postgraduate studies at the Prague Academy of Music. She loves travelling and meeting new people, and
                    enjoys the freedom to be creative that comes with a career in bookselling.
                  </p>
                </div>
              </div>
            </div>
          </section>

          <section className="container-wide books">
            <h2>
              We are on a mission to bring top quality English
              <br />
              and foreign language books to children around the world
            </h2>
            <div className="container-books">
              <div className="row">
                <div className="col-4 col-md-12 books-block">
                  <Link to="/#eshop">
                    <div className="books-block-circle circle-1"></div>
                    <p>Browse Books</p>
                  </Link>
                </div>
                <div className="col-4 col-md-12 books-block">
                  <Link to="/bookselling">
                    <div className="books-block-circle  circle-2"></div>
                    <p>
                      Bookselling
                      <br />
                      and Distribution
                    </p>
                  </Link>
                </div>
                <div className="col-4 col-md-12 books-block">
                  <Link to="/school-book-fairs">
                    <div className="books-block-circle  circle-3"></div>
                    <p>School Book Fairs</p>
                  </Link>
                </div>
              </div>
            </div>
          </section>

          <section className="books-network">
            <div className="container">
              <div className="row">
                <div className="books-network-left col-7 col-md-12">
                  <img src={LogoBooksellers} alt="LogoChildrenBooksellersWorldwide" />
                  <h2>
                    Creating a network
                    <br />
                    of true booklovers
                  </h2>
                  <p>
                    Moonwood offers customers the possibility of <Link to="/#eshop">browsing and buying children's books for all ages and interests</Link>, with delivery of eshop orders throughout Europe, and{" "}
                    <a href="mailto:info@moonwood-books.com?subject=Interested in receiving catalogues">catalogue orders</a> throughout Europe and worldwide.
                  </p>
                  <p>
                    Moonwood <a href="mailto:info@moonwood-books.com?subject=Interested in selling children's books">invites you to join the international network 'Children's Booksellers Worldwide'</a>, sharing the joy of reading with many more children and families, while
                    selling a wide range of children's books in English and foreign languages.
                  </p>
                  <p>Experienced network representatives in many countries offer support in guiding new members through the initial stages, welcoming them to the world of bookselling and supporting them further as they progress on their bookselling venture.</p>
                </div>
                <div className="books-network-right col-5 col-md-12">
                  <ViewportBlock2 /* onEnterViewport={() => console.log("enter")} onLeaveViewport={() => console.log("leave")} */ />
                </div>
              </div>
            </div>
          </section>

          <section id="container-books-publishers">
            <div id="publishers" className="books-publishers">
              <h2>Publishers we offer</h2>
              <p>
                Whether you are a customer, school, bookseller or distributor, we offer more than thirty publishers of English
                <br />
                children's books for you to choose from, in addition to a wide range of children's books in foreign languages.
              </p>
              <div className="logos row">
                <div className="logos-block col-12">
                  {props.data.logos.edges.slice(0, 8).map((item, i) => {
                    return (
                      <Img
                        fluid={item.node.childImageSharp.fluid}
                        imgStyle={{ objectFit: "contain", maxWidth: item.node.childImageSharp.fluid.presentationWidth, maxHeight: item.node.childImageSharp.fluid.presentationHeight, width: "100%", height: "100%" }}
                        style={{ objectFit: "contain", maxWidth: item.node.childImageSharp.fluid.presentationWidth, maxHeight: item.node.childImageSharp.fluid.presentationHeight, width: "100%", height: "100%" }}
                        alt={`PublisherLogo${i + 1}`}
                        key={i}
                      />
                    )
                  })}
                </div>
                <div className="logos-block col-12">
                  {props.data.logos.edges.slice(8, 16).map((item, i) => {
                    return (
                      <Img
                        fluid={item.node.childImageSharp.fluid}
                        imgStyle={{ objectFit: "contain", maxWidth: item.node.childImageSharp.fluid.presentationWidth, maxHeight: item.node.childImageSharp.fluid.presentationHeight, width: "100%", height: "100%" }}
                        style={{ objectFit: "contain", maxWidth: item.node.childImageSharp.fluid.presentationWidth, maxHeight: item.node.childImageSharp.fluid.presentationHeight, width: "100%", height: "100%" }}
                        alt={`PublisherLogo${i + 9}`}
                        key={i}
                      />
                    )
                  })}
                </div>
                <div className="logos-block col-12">
                  {props.data.logos.edges.slice(16, 23).map((item, i) => {
                    return (
                      <Img
                        fluid={item.node.childImageSharp.fluid}
                        imgStyle={{ objectFit: "contain", maxWidth: item.node.childImageSharp.fluid.presentationWidth, maxHeight: item.node.childImageSharp.fluid.presentationHeight, width: "100%", height: "100%" }}
                        style={{ objectFit: "contain", maxWidth: item.node.childImageSharp.fluid.presentationWidth, maxHeight: item.node.childImageSharp.fluid.presentationHeight, width: "100%", height: "100%" }}
                        className={i === 1 ? "oxfordLogo" : null}
                        alt={`PublisherLogo${i + 17}`}
                        key={i}
                      />
                    )
                  })}
                </div>
                <div className="logos-block col-12">
                  {props.data.logos.edges.slice(23, 33).map((item, i) => {
                    return (
                      <Img
                        fluid={item.node.childImageSharp.fluid}
                        imgStyle={{ objectFit: "contain", maxWidth: item.node.childImageSharp.fluid.presentationWidth, maxHeight: item.node.childImageSharp.fluid.presentationHeight, width: "100%", height: "100%" }}
                        style={{ objectFit: "contain", maxWidth: item.node.childImageSharp.fluid.presentationWidth, maxHeight: item.node.childImageSharp.fluid.presentationHeight, width: "100%", height: "100%" }}
                        alt={`PublisherLogo${i + 24}`}
                        key={i}
                      />
                    )
                  })}
                </div>
              </div>

              <p>
                Usborne Publishing, Dorling Kindersley, Penguin Random House UK, Puffin, Ladybird, Nosy Crow, Scholastic UK, HarperCollins&nbsp;Children's&nbsp;Books, Macmillan Children's Books, Campbell, National Geographic Kids, Barefoot Books,
                Hodder&nbsp;Children's&nbsp;Books, Jolly Phonics, Orchard Books, Simon and Schuster, Oxford Children’s Books, Priddy&nbsp;Books, Pavilion&nbsp;Children's&nbsp;Books, Walker Books, Andersen Press, Scholastic US, Penguin Random&nbsp;House&nbsp;US,&nbsp;Hyperion
                Books for Kids, Big&nbsp;Picture&nbsp;Press, Templar&nbsp;Books, Piccadilly Press, Hot Key Books, Button Books, Quarto Kids, Egmont, Bloomsbury, Orion&nbsp;Children's&nbsp;Books, Quercus&nbsp;Children's&nbsp;Books, Pat-a-Cake, Franklin Watts, Klutz (educational
                toys), Arcturus Publishing
              </p>
            </div>
          </section>

          <section className="container about-library">
            <div className="row about-detail">
              <div className="col-6 col-md-12 about-detail-left">
                <div className="image-container">
                  <Img fluid={sourcesHomeLibrary} style={{ height: "100%", width: "100%" }} imgStyle={{ objectFit: "contain" }} alt="homeLibrary" />
                </div>
                <p className="photo-credit mobileShow">Photo credit: Helen Milan Home</p>
                <div className="divider divider-rotate">
                  <div className="divider-inner-height show hide"></div>
                </div>
              </div>
              <div className="col-6 col-md-12 about-detail-right">
                <h2>Creating a home library</h2>
                <p>
                  Creating a home library for your children is a wonderful way to inspire a love of reading. At Moonwood we are happy to help families choose books suitable for the ages and interests of their children, providing an individual service with friendly advice and
                  tailored recommendations. We take care of the complete home library ordering process (catalogues can be provided on request), offering a generous home library discount and ensuring all-round customer satisfaction.
                </p>
                <a href="mailto:info@moonwood-books.com?subject=Interested in creating a home library" className="btn btn-page showBtn">
                  Get in touch to find out more
                </a>
                <a href="mailto:info@moonwood-books.com?subject=Interested in creating a home library" className="btn btn-page hideBtn">
                  Learn more
                </a>
              </div>
              <p className="photo-credit mobileHide">Photo credit: Helen Milan Home</p>
            </div>
          </section>
        </div>
      </Layout>
    </>
  )
}

export default AboutUs

export const query = graphql`
  query {
    homeLibrary: file(relativePath: { eq: "about-us/home-library/home-library.png" }) {
      childImageSharp {
        fluid(maxHeight: 579, maxWidth: 388, quality: 90, pngQuality: 90, fit: CONTAIN) {
          ...GatsbyImageSharpFluid_withWebp
          ...GatsbyImageSharpFluidLimitPresentationSize
          presentationHeight
          presentationWidth
        }
      }
    }
    homeLibraryMobile: file(relativePath: { eq: "about-us/home-library/home-library-2x.jpg" }) {
      childImageSharp {
        fluid(maxHeight: 925, maxWidth: 620, quality: 90, pngQuality: 90, fit: CONTAIN) {
          ...GatsbyImageSharpFluid_withWebp
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
    zoeVoborilova: file(relativePath: { eq: "about-us/zoe-voborilova/zoe-voborilova.png" }) {
      childImageSharp {
        fluid(maxWidth: 366, quality: 90, pngQuality: 90) {
          ...GatsbyImageSharpFluid_withWebp
          ...GatsbyImageSharpFluidLimitPresentationSize
          presentationHeight
          presentationWidth
        }
      }
    }
    zoeVoborilovaMobile: file(relativePath: { eq: "about-us/zoe-voborilova/zoe-voborilova-2x.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 622, maxHeight: 780, quality: 90, pngQuality: 90) {
          ...GatsbyImageSharpFluid_withWebp
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
    logos: allFile(filter: { relativeDirectory: { eq: "about-us/publishers" } }, sort: { fields: name, order: ASC }) {
      edges {
        node {
          childImageSharp {
            fluid(maxWidth: 268, quality: 100, pngQuality: 100) {
              ...GatsbyImageSharpFluid_withWebp
              ...GatsbyImageSharpFluidLimitPresentationSize
              presentationHeight
              presentationWidth
            }
          }
        }
      }
    }
  }
`
