import React from "react"

export default props => {
  //   console.log(props.play)
  return (
    <div className={`books-network-right-image ${props.play === true ? "play" : ""}`}>
      <div className="dots"></div>
    </div>
  )
}
